<template>
  <div class="pwd">
    <center-title title="密码修改" :border="false"></center-title>
    <div class="pwd-box">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="0"
        class="demo-ruleForm"
      >
        <el-form-item prop="true_name">
          <el-input v-model="ruleForm.true_name" placeholder="请填写真实的姓名">
            <div slot="prefix" class="label row">
              <img src="@/assets/icon/icon-user2.png" alt="" />
              真实姓名
            </div>
          </el-input>
        </el-form-item>
        <el-form-item prop="mobile">
          <el-input v-model="ruleForm.mobile" placeholder="注册手机号">
            <div slot="prefix" class="label row">
              <img src="@/assets/icon/icon-tel1.png" alt="" />
              手机号
            </div>
          </el-input>
        </el-form-item>
        <el-form-item prop="code" class="code">
          <el-input v-model="ruleForm.code" placeholder="请输入验证码">
            <div slot="prefix" class="label row">
              <img src="@/assets/icon/icon-code.png" alt="" />
              验证码
            </div>
            <div
              slot="append"
              class="bg-center row-center lighter sm btn-code"
              @click="countDown"
            >
              {{ content }}
            </div>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            v-model="ruleForm.password"
            placeholder="建议6-20位字母和数字等组合密码"
          >
            <div slot="prefix" class="label row">
              <img src="@/assets/icon/icon-pwd1.png" alt="" />
              设置密码
            </div>
          </el-input>
        </el-form-item>
        <el-form-item prop="repassword">
          <el-input
            type="password"
            v-model="ruleForm.repassword"
            placeholder="请输入确认密码"
          >
            <div slot="prefix" class="label row">
              <img src="@/assets/icon/icon-pwd1.png" alt="" />
              确认密码
            </div>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            class="white md bg-primary btn-sure"
            @click="submitForm('ruleForm')"
          >
            确认修改
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
import { vxRule, rules } from "@/utils/validator";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
  },
  data() {
    // 密码验证
    var validatePwdContrast = (rule, value, callback) => {
      if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        true_name: "", //用户名称
        mobile: "", //电话号码
        code: "", //验证码
        password: "", //密码
        repassword: "", //重置密码
      },
      rules: {
        true_name: vxRule(true, null, "blur", "请填写真实的姓名"),
        mobile: vxRule(true, "Mobile", "blur", "请填写手机号码"),
        code: vxRule(true, null, "blur", "请输入验证码"),
        password: vxRule(true, "Password", "blur", "请输入验证码"),
        repassword: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
          { validator: validatePwdContrast, trigger: "blur" },
        ],
      },
      content: "发送短信验证码",
      totalTime: 60,
      canClick: true, //添加canClick
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  watch: {},
  methods: {
    ...mapActions("user", ["EXIT_LOGIN"]),
    countDown() {
      if (!this.canClick) return; //改动的是这两行代码
      let { result, errMsg } = rules.Mobile(this.ruleForm.mobile);
      if (!result) {
        this.$message.error(errMsg);
        return;
      }
      this.canClick = false;
      this.content = this.totalTime + "s后重新发送";
      this.$api.getSmsSendApi({
        mobile: this.ruleForm.mobile,
        key: "ZHMM",
      });
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.content = this.totalTime + "s后重新发送";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.content = "重新发送验证码";
          this.totalTime = 60;
          this.canClick = true; //这里重新开启
        }
      }, 1000);
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.loginRequest) return;
          this.loginRequest = true;
          let { code, msg, data } = await this.$api.forgetPasswordApi(
            this.ruleForm
          );
          this.loginRequest = false;
          if (code == 1) {
            this.$message({
              message: "修改成功，请重新登录",
              type: "success",
              onClose: () => {
                this.EXIT_LOGIN(this.userInfo);
                router.replace({
                  path: "/",
                });
              },
            });
          }
        } else {
          return false;
        }
      });
    },
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
/deep/ .pwd-box {
  width: 638px;

  .el-form-item {
    margin-bottom: 30px;
  }

  .el-input__inner {
    resize: none;
    border-radius: 2px;
    padding-left: 148px;
    height: 54px;
    line-height: 54px;
  }

  .el-input__prefix {
    display: flex;
    align-items: center;
  }

  .label {
    padding-left: 20px;
    width: 120px;
    position: relative;

    &::before {
      position: absolute;
      content: "";
      width: 1px;
      height: 35px;
      background-color: #ececec;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    img {
      color: $-color-lighter;
      width: 12px;
      margin-right: 10px;
    }
  }

  .code {
    .el-input-group {
      display: flex;
    }

    .el-input__inner {
      margin-right: 8px;
    }

    .el-input-group__append {
      border: none;
      padding: 0 35px;
      min-width: 175px;

      .btn-code {
        height: 100%;
        cursor: pointer;
      }
    }
  }

  .btn-sure {
    width: 100%;
    border-radius: 2px;
    height: 54px;
    line-height: 54px;
    padding: 0;
  }
}
</style>
